'use strict';

$(document).ready(function () {
  setNextLazyWaypoint();
});

var jcLoader = '<div class="jc-loader text-center mx-auto"><div class="sk-three-bounce"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div></div>';

function setNextLazyWaypoint() {
  $('.lazy-load').eq(0).waypoint({
    handler: function handler() {
      lazyLoadSection($(this.element));
    },
    offset: $(window).height() + 300
  });
}

function lazyLoadSection(section) {

  section.append(jcLoader);

  var sectionData = section.data();
  sectionData.action = 'jc_ajax_lazy_load_section';

  $.ajax({
    url: ajaxHandler.url,
    type: 'POST',
    data: sectionData,
    success: function success(response) {
      section.after(response.data.html);
      section.remove();
      setNextLazyWaypoint();
    },
    error: function error(data) {
      console.log('erro ajax');
    }
  });
}